// src/pages/CompanyDashboard.js
import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AppBar, Tabs, Tab, Box, Toolbar, Typography, Button } from '@mui/material';
import CompanyStoreDisplay from '../components/CompanyStoreDisplay'; // Import the store display component
import CompanyAssignSalesPeople from '../components/CompanyAssignSalesPeople'; // Import the salespeople component

const CompanyDashboard = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const fetchCompany = useCallback(async () => {
    try {
      const response = await axios.get(`https://apiserver.talaura.net/api/companies/${companyId}`);
      setCompany(response.data);
    } catch (error) {
      console.error('Error fetching company details:', error);
    }
  }, [companyId]);

  useEffect(() => {
    // Fetch company details
    fetchCompany();
  }, [fetchCompany]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleLogout = () => {
    // Clear authentication details from localStorage
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userRole');
    localStorage.removeItem('companyId');
    // Redirect to login page
    navigate('/');
  };

  return (
    <div>
      {company ? (
        <>
          <AppBar position="static" sx={{ backgroundColor: '#3f51b5' }}>
            <Toolbar>
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                {company.CompanyDisplayName} Dashboard
              </Typography>
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            </Toolbar>
          </AppBar>
          <AppBar position="static" sx={{ backgroundColor: '#3f51b5', marginTop: 1 }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor="inherit"
              sx={{
                '& .MuiTab-root': {
                  color: 'white',
                },
                '& .Mui-selected': {
                  color: '#ffeb3b',
                },
              }}
            >
              <Tab label="Sales People" />
              <Tab label="Stores" />
            </Tabs>
          </AppBar>
          <Box p={3}>
            {selectedTab === 0 && (
              <CompanyAssignSalesPeople
                companyId={companyId}
                companyDisplayName={company.CompanyDisplayName}
              />
            )}
            {selectedTab === 1 && <CompanyStoreDisplay companyId={companyId} showDeleteButton />}
          </Box>
        </>
      ) : (
        <p>Loading company store information...</p>
      )}
    </div>
  );
};

export default CompanyDashboard;
