import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Tabs, Tab, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Unassigned from '../components/Unassigned';
import Assigned from '../components/Assigned';
import CompanyStoreInfo from '../components/CompanyStoreInfo';
import AssignSalesPeople from '../components/AssignSalesPeople';
import Wearables from '../components/Wearables';

const AdminDashboard = () => {
  const navigate = useNavigate();

  // Retrieve the saved tab index from localStorage or default to 0
  const [selectedTab, setSelectedTab] = useState(() => {
    return parseInt(localStorage.getItem('selectedTab')) || 0;
  });

  useEffect(() => {
    // Save the selected tab index to localStorage whenever it changes
    localStorage.setItem('selectedTab', selectedTab);
  }, [selectedTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleLogout = () => {
    // Clear authentication data from localStorage
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userRole');

    // Navigate back to the login page
    navigate('/');
  };

  return (
    <div className="AdminDashboard">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Admin Dashboard
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="inherit"
          indicatorColor="secondary"
          sx={{
            '& .Mui-selected': {
              color: '#ffffff', // Ensure selected tab text color is white for visibility
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#ff4081', // Set indicator color for better contrast
            },
          }}
        >
          <Tab label="Unassigned Dockers" />
          <Tab label="Assigned Dockers" />
          <Tab label="Company & Store Info" />
          <Tab label="Assign Sales People" />
          <Tab label="Wearables" />
        </Tabs>
      </AppBar>
      <Box p={3}>
        {selectedTab === 0 && <Unassigned />}
        {selectedTab === 1 && <Assigned />}
        {selectedTab === 2 && <CompanyStoreInfo />}
        {selectedTab === 3 && <AssignSalesPeople />}
        {selectedTab === 4 && <Wearables />}
      </Box>
    </div>
  );
};

export default AdminDashboard;
