// src/components/CompanyStoreDisplay.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const CompanyStoreDisplay = ({ companyId }) => {
  const [companyData, setCompanyData] = useState(null);
  const [dockersData, setDockersData] = useState([]);
  const [open, setOpen] = useState(false);
  const [storeId, setStoreId] = useState('');
  const [location, setLocation] = useState('');

  useEffect(() => {
    fetchCompanyData();
    fetchDockersData();
  }, [companyId]);

  const fetchCompanyData = () => {
    axios.get(`https://apiserver.talaura.net/api/companies/${companyId}`)
      .then(response => {
        setCompanyData(response.data);
      })
      .catch(error => {
        console.error('Error fetching company data:', error);
      });
  };

  const fetchDockersData = () => {
    axios.get(`https://apiserver.talaura.net/api/dockers`)
      .then(response => {
        setDockersData(response.data);
      })
      .catch(error => {
        console.error('Error fetching dockers data:', error);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStoreId('');
    setLocation('');
  };

  const handleAddStore = () => {
    if (!location.trim()) {
      alert('Please fill in the required field: Location.');
      return;
    }

    let storeIDValue = storeId.trim();
    if (!storeIDValue) {
      storeIDValue = `ST${Date.now()}${Math.floor(Math.random() * 1000)}`;
    }

    const storeData = {
      store: {
        StoreID: storeIDValue,
        Location: location,
      },
    };

    axios.put(`https://apiserver.talaura.net/api/companies/${companyId}/add-store`, storeData)
      .then(() => {
        alert('Store added successfully');
        setStoreId('');
        setLocation('');
        fetchCompanyData();
        handleClose();
      })
      .catch((error) => {
        console.error('Error adding store:', error);
        alert('Error adding store');
      });
  };

  const handleDeleteStore = (storeID) => {
    if (!window.confirm("Are you sure you want to delete this store?")) return;

    axios.put(`https://apiserver.talaura.net/api/companies/${companyId}/delete-store`, { storeID })
      .then(() => {
        alert('Store deleted successfully');
        fetchCompanyData();
      })
      .catch((error) => {
        console.error('Error deleting store:', error);
        alert('Error deleting store');
      });
  };

  return (
    <div style={{ marginTop: '16px' }}>
      {companyData ? (
        <div>
          <Typography variant="h6" gutterBottom>
            {companyData.CompanyName} ({companyData.CompanyDisplayName}) - {companyData.CompanyEmailTalaura}
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="company store info table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>StoreID</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Location</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Assigned Dockers</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companyData.Stores.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No stores available for this company.
                    </TableCell>
                  </TableRow>
                ) : (
                  companyData.Stores.map((store) => {
                    const assignedDockers = dockersData
                      .filter((docker) => docker.StoreID === store.StoreID)
                      .map((docker) => docker.DockerID)
                      .join(', ');

                    return (
                      <TableRow key={store.StoreID}>
                        <TableCell>{store.StoreID}</TableCell>
                        <TableCell>{store.Location}</TableCell>
                        <TableCell>{assignedDockers || 'No dockers assigned'}</TableCell>
                        <TableCell>
                          <IconButton
                            color="secondary"
                            onClick={() => handleDeleteStore(store.StoreID)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ marginTop: 2 }}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add New Store
            </Button>
          </Box>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add New Store</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the Store ID (optional) and Location for the new store.
              </DialogContentText>
              <Grid container spacing={2} sx={{ marginTop: 1 }}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Store ID (Optional)"
                    value={storeId}
                    onChange={(e) => setStoreId(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    fullWidth
                    required
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleAddStore}>Add Store</Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <Typography align="center">Loading company store information...</Typography>
      )}
    </div>
  );
};

export default CompanyStoreDisplay;
