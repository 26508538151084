import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Box, Typography } from '@mui/material';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // Send login request to the backend
      const response = await axios.post('https://apiserver.talaura.net/api/auth/login', { email, password });
  
      const { role, companyId } = response.data;
  
      // Update state in App component via onLogin
      onLogin(true, role);
  
      // Navigate to the Admin Dashboard or Company Dashboard
      if (role === 'admin') {
        navigate('/admin-dashboard');
      } else if (role === 'company') {
        navigate(`/company-dashboard/${companyId}`); // Use the companyId from the response
      }
    } catch (err) {
      setError('Invalid credentials: ' + err.message);
    }
  };

  return (
    <Box>
      <Typography variant="h4">Login</Typography>
      <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" onClick={handleLogin}>
        Login
      </Button>
      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

export default Login;
