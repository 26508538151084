import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

const CompanyAssignSalesPeople = ({ companyId, companyDisplayName = '' }) => {
  const [salesPeople, setSalesPeople] = useState([]);
  const [newSalesPersonName, setNewSalesPersonName] = useState('');
  const [newSalesPersonEmail, setNewSalesPersonEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [employeeID, setEmployeeID] = useState('');
  const [jobPosition, setJobPosition] = useState('Sales Store Executive');
  const [otherJobPosition, setOtherJobPosition] = useState('');
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editSalesPersonId, setEditSalesPersonId] = useState(null);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState('');
  const [uniqueCodeToShow, setUniqueCodeToShow] = useState(null);

  // Construct the password from companyDisplayName
  const PASSWORD = `${companyDisplayName.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}123`;

  const fetchSalesPeople = useCallback(() => {
    axios.get(`https://apiserver.talaura.net/api/companies/${companyId}`)
      .then(response => {
        setSalesPeople(response.data.SalesPeople || []);
      })
      .catch(error => {
        console.error('Error fetching salespeople:', error);
      });
  }, [companyId]);

  useEffect(() => {
    fetchSalesPeople();
  }, [fetchSalesPeople]);

  const handleOpen = (salesPerson = null) => {
    if (salesPerson) {
      // Edit mode - populate existing data
      setEditMode(true);
      setEditSalesPersonId(salesPerson._id);
      setNewSalesPersonName(salesPerson.SalesPersonName);
      setNewSalesPersonEmail(salesPerson.SalesPersonEmail);
      setPhoneNumber(salesPerson.PhoneNumber);
      setEmployeeID(salesPerson.EmployeeID);
      setJobPosition(salesPerson.JobPosition);
      if (salesPerson.JobPosition === 'Other') {
        setOtherJobPosition(salesPerson.JobPosition);
      }
    } else {
      // Add mode - clear fields
      setEditMode(false);
      setNewSalesPersonName('');
      setNewSalesPersonEmail('');
      setPhoneNumber('');
      setEmployeeID('');
      setJobPosition('Sales Store Executive');
      setOtherJobPosition('');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Clear the input fields when the dialog is closed
    setNewSalesPersonName('');
    setNewSalesPersonEmail('');
    setPhoneNumber('');
    setEmployeeID('');
    setJobPosition('Sales Store Executive');
    setOtherJobPosition('');
    setEditMode(false);
    setEditSalesPersonId(null);
  };

  const handleAddOrEditSalesPerson = () => {
    // Validate required fields
    if (!newSalesPersonName || !newSalesPersonEmail || !phoneNumber || !employeeID) {
      alert('Please fill in all required fields: Name, Email, Phone Number, and Employee ID.');
      return;
    }

    // Validate phone number
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
      alert('Please enter a valid 10-digit phone number.');
      return;
    }

    const position = jobPosition === 'Other' ? otherJobPosition : jobPosition;

    const salesPersonData = {
      SalesPersonName: newSalesPersonName,
      SalesPersonEmail: newSalesPersonEmail,
      PhoneNumber: phoneNumber,
      EmployeeID: employeeID,
      JobPosition: position
    };

    if (editMode) {
      // Update existing salesperson
      axios.patch(`https://apiserver.talaura.net/api/companies/${companyId}/update-salesperson`, {
        SalesPersonID: editSalesPersonId,
        updatedSalesPerson: salesPersonData
      })
        .then(() => {
          handleClose();
          fetchSalesPeople(); // Refresh the list of salespeople
        })
        .catch(error => {
          console.error('Error updating salesperson:', error);
          alert('Error updating salesperson: ' + (error.response ? error.response.data.message : error.message));
        });
    } else {
      // Add new salesperson
      axios.patch(`https://apiserver.talaura.net/api/companies/${companyId}`, {
        SalesPeople: salesPersonData
      })
        .then(() => {
          handleClose();
          fetchSalesPeople(); // Refresh the list of salespeople
        })
        .catch(error => {
          console.error('Error adding salesperson:', error);
          alert('Error adding salesperson: ' + (error.response ? error.response.data.message : error.message));
        });
    }
  };

  const handleRemoveSalesPerson = (salesPersonEmail) => {
    axios.patch(`https://apiserver.talaura.net/api/companies/${companyId}/remove-salesperson`, {
      SalesPersonEmail: salesPersonEmail
    })
      .then(() => {
        fetchSalesPeople(); // Refresh the list of salespeople after successful removal
      })
      .catch(error => {
        console.error('Error removing salesperson:', error);
        alert('Error removing salesperson: ' + (error.response ? error.response.data.message : error.message));
      });
  };

  const togglePasswordDialog = (salesPerson) => {
    setUniqueCodeToShow(salesPerson);
    setPasswordDialogOpen(true);
  };

  const handlePasswordSubmit = () => {
    if (enteredPassword === PASSWORD) {
      // Show the unique code in dialog box
      setPasswordDialogOpen(false);
    } else {
      alert('Incorrect password');
      setEnteredPassword('');
    }
  };

  const handlePasswordDialogClose = () => {
    setEnteredPassword('');
    setPasswordDialogOpen(false);
    setUniqueCodeToShow(null);
  };

  return (
    <div>
      <h3>Sales People</h3>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="salespeople table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Salesperson Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Salesperson Email</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Phone Number</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Employee ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Job Position</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Wearable ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Unique ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesPeople.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No salespeople assigned yet.
                </TableCell>
              </TableRow>
            ) : (
              salesPeople.map((salesPerson) => (
                <TableRow key={salesPerson._id}>
                  <TableCell>{salesPerson.SalesPersonName}</TableCell>
                  <TableCell>{salesPerson.SalesPersonEmail}</TableCell>
                  <TableCell>{salesPerson.PhoneNumber}</TableCell>
                  <TableCell>{salesPerson.EmployeeID}</TableCell>
                  <TableCell>{salesPerson.JobPosition}</TableCell>
                  <TableCell>{salesPerson.WearableID ? salesPerson.WearableID : 'Not Assigned'}</TableCell>
                  <TableCell>
                    {'**********'}
                    <IconButton onClick={() => togglePasswordDialog(salesPerson)}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        onClick={() => handleOpen(salesPerson)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        color="secondary"
                        onClick={() => handleRemoveSalesPerson(salesPerson.SalesPersonEmail)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Button to open the Add Salesperson dialog */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => handleOpen()}
        sx={{ marginTop: 2 }}
      >
        Add New Salesperson
      </Button>

      {/* Dialog to add/edit a salesperson */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editMode ? 'Edit Salesperson' : 'Add New Salesperson'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Salesperson Name"
            value={newSalesPersonName}
            onChange={(e) => setNewSalesPersonName(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Salesperson Email"
            value={newSalesPersonEmail}
            onChange={(e) => setNewSalesPersonEmail(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Employee ID"
            value={employeeID}
            onChange={(e) => setEmployeeID(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
          />

          {/* Job Position Selection */}
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="job-position-label">Job Position</InputLabel>
            <Select
              labelId="job-position-label"
              value={jobPosition}
              onChange={(e) => setJobPosition(e.target.value)}
              label="Job Position"
            >
              <MenuItem value="Sales Store Executive">Sales Store Executive</MenuItem>
              <MenuItem value="Sales Store Manager">Sales Store Manager</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>

          {jobPosition === 'Other' && (
            <TextField
              label="Specify Job Position"
              value={otherJobPosition}
              onChange={(e) => setOtherJobPosition(e.target.value)}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddOrEditSalesPerson} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Password Dialog to show Unique Code */}
      <Dialog open={passwordDialogOpen} onClose={handlePasswordDialogClose}>
        <DialogTitle>Enter Password to View Unique Code</DialogTitle>
        <DialogContent>
          <TextField
            label="Password"
            type="password"
            value={enteredPassword}
            onChange={(e) => setEnteredPassword(e.target.value)}
            fullWidth
            autoComplete="off"
          />
          {uniqueCodeToShow && enteredPassword === PASSWORD && (
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              Unique Code: {uniqueCodeToShow.UniqueCode}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePasswordSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CompanyAssignSalesPeople;
