// src/components/Assigned.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const socket = io('https://apiserver.talaura.net/');

const Assigned = () => {
  const [companiesData, setCompaniesData] = useState([]);
  const [expandedAccordion, setExpandedAccordion] = useState(
    localStorage.getItem('expandedAccordion') || false
  );

  useEffect(() => {
    fetchAssignedDockers();

    // Listen for updates via WebSocket
    socket.on('updateDockerData', () => {
      fetchAssignedDockers();
    });

    return () => {
      socket.off('updateDockerData');
    };
  }, []);

  const fetchAssignedDockers = () => {
    axios.get('https://apiserver.talaura.net/api/dockers')
      .then(response => {
        const assigned = response.data.filter(docker => docker.Assigned === true);

        // Sort assigned dockers by Company Name, then group them by company
        const companiesMap = {};
        assigned.forEach(docker => {
          if (!docker.Company || !docker.Company.CompanyName) return;

          if (!companiesMap[docker.Company.CompanyName]) {
            companiesMap[docker.Company.CompanyName] = {
              companyName: docker.Company.CompanyName,
              companyId: docker.Company._id,
              dockers: []
            };
          }
          companiesMap[docker.Company.CompanyName].dockers.push(docker);
        });

        const companiesArray = Object.keys(companiesMap).map(companyName => ({
          companyName,
          dockers: companiesMap[companyName].dockers
        }));

        // Sort companies array alphabetically by company name
        companiesArray.sort((a, b) => a.companyName.localeCompare(b.companyName));

        setCompaniesData(companiesArray);
      })
      .catch(error => {
        console.error('Error fetching assigned dockers:', error);
      });
  };

  // Unassign Docker function
  const handleUnassignDocker = (dockerId) => {
    axios.patch(`https://apiserver.talaura.net/api/dockers/${dockerId}`, {
      Assigned: false,
      StoreID: '',
      Location: '',
      Company: null
    })
    .then(() => {
      // Update the frontend state by refetching the data
      fetchAssignedDockers();
    })
    .catch(error => {
      console.error('Error unassigning docker:', error);
    });
  };

  // Handle accordion expand/collapse
  const handleAccordionChange = (companyName) => (event, isExpanded) => {
    const newExpandedState = isExpanded ? companyName : false;
    setExpandedAccordion(newExpandedState);
    localStorage.setItem('expandedAccordion', newExpandedState); // Save state in local storage
  };

  return (
    <div style={{ marginTop: '16px' }}>
      {companiesData.length === 0 ? (
        <Typography align="center">No assigned dockers available.</Typography>
      ) : (
        companiesData.map((company) => (
          <Accordion
            key={company.companyName}
            expanded={expandedAccordion === company.companyName}
            onChange={handleAccordionChange(company.companyName)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${company.companyName}-content`}
              id={`panel-${company.companyName}-header`}
            >
              <Typography variant="h6">{company.companyName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="assigned docker table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>DockerID</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>StoreID</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Location</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {company.dockers.map((docker) => (
                      <TableRow key={docker._id}>
                        <TableCell>{docker.DockerID}</TableCell>
                        <TableCell>{docker.StoreID}</TableCell>
                        <TableCell>{docker.Location}</TableCell>
                        <TableCell>
                          <IconButton
                            color="secondary"
                            onClick={() => handleUnassignDocker(docker._id)}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </div>
  );
};

export default Assigned;
