// src/components/AssignSalesPeople.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  Tooltip,
  FormControl,
  InputLabel
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

const socket = io("https://apiserver.talaura.net/"); // Update to match your backend address

const AssignSalesPeople = () => {
  const [companies, setCompanies] = useState([]);
  const [salesPersonName, setSalesPersonName] = useState('');
  const [salesPersonEmail, setSalesPersonEmail] = useState('');
  const [employeeID, setEmployeeID] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [jobPosition, setJobPosition] = useState('Sales Store Executive');
  const [otherJobPosition, setOtherJobPosition] = useState('');
  const [expanded, setExpanded] = useState(localStorage.getItem('expandedAccordion') || false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editSalesPersonId, setEditSalesPersonId] = useState(null);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [passwordAttemptSalesPersonId, setPasswordAttemptSalesPersonId] = useState(null);
  const [uniqueCodeToShow, setUniqueCodeToShow] = useState('');

  // Fetch assigned salespeople for companies
  const fetchCompanies = useCallback(() => {
    axios.get('https://apiserver.talaura.net/api/companies')
      .then(response => {
        setCompanies(response.data);
      })
      .catch(error => {
        console.error('Error fetching companies:', error);
      });
  }, []);

  useEffect(() => {
    fetchCompanies();

    // Listen for WebSocket updates
    socket.on("updateCompanyData", (updatedCompany) => {
      setCompanies(prevCompanies => {
        const index = prevCompanies.findIndex(c => c._id === updatedCompany._id);
        if (index !== -1) {
          const newCompanies = [...prevCompanies];
          newCompanies[index] = updatedCompany;
          return newCompanies;
        } else {
          return [...prevCompanies, updatedCompany];
        }
      });
    });

    return () => {
      socket.off("updateCompanyData");
    };
  }, [fetchCompanies]);

  const handleAssignSalesPerson = () => {
    // Validate required fields
    if (!salesPersonName.trim() || !salesPersonEmail.trim() || !phoneNumber.trim() || !employeeID.trim()) {
      alert('Please enter all required fields: Sales Person Name, Sales Person Email, Phone Number, and Employee ID.');
      return;
    }

    // Validate phone number with regex (simple validation)
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
      alert('Please enter a valid 10-digit phone number.');
      return;
    }

    const position = jobPosition === 'Other' ? otherJobPosition : jobPosition;

    const newSalesPerson = {
      SalesPersonName: salesPersonName,
      SalesPersonEmail: salesPersonEmail,
      EmployeeID: employeeID,
      PhoneNumber: phoneNumber,
      JobPosition: position
    };

    if (editMode) {
      // Edit existing salesperson
      axios.patch(`https://apiserver.talaura.net/api/companies/${selectedCompanyId}/update-salesperson`, {
        SalesPersonID: editSalesPersonId,
        updatedSalesPerson: newSalesPerson
      })
        .then(() => {
          handleCloseDialog();
          fetchCompanies();  // Refresh updated list of companies
        })
        .catch(error => {
          console.error('Error updating salesperson:', error);
        });
    } else {
      // Add new salesperson
      axios.patch(`https://apiserver.talaura.net/api/companies/${selectedCompanyId}`, {
        SalesPeople: newSalesPerson
      })
        .then(() => {
          handleCloseDialog();
          fetchCompanies();  // Refresh updated list of companies
        })
        .catch(error => {
          console.error('Error assigning salesperson:', error);
        });
    }
  };

  const handleRemoveSalesPerson = (companyId, salesPersonEmail) => {
    axios.patch(`https://apiserver.talaura.net/api/companies/${companyId}/remove-salesperson`, {
      SalesPersonEmail: salesPersonEmail
    })
      .then(() => {
        alert('Sales person removed successfully!');
        fetchCompanies(); // Refresh updated list of companies
      })
      .catch(error => {
        console.error('Error removing sales person:', error);
      });
  };

  const handleAccordionChange = (companyId) => (event, isExpanded) => {
    const newExpanded = isExpanded ? companyId : false;
    setExpanded(newExpanded);
    localStorage.setItem('expandedAccordion', newExpanded);
  };

  const handleOpenDialog = (companyId, salesPerson = null) => {
    setSelectedCompanyId(companyId);
    if (salesPerson) {
      // Set up fields for edit mode
      setEditMode(true);
      setEditSalesPersonId(salesPerson._id);
      setSalesPersonName(salesPerson.SalesPersonName);
      setSalesPersonEmail(salesPerson.SalesPersonEmail);
      setPhoneNumber(salesPerson.PhoneNumber);
      setEmployeeID(salesPerson.EmployeeID);
      setJobPosition(salesPerson.JobPosition);
      if (salesPerson.JobPosition === 'Other') {
        setOtherJobPosition(salesPerson.JobPosition);
      }
    } else {
      // Set up fields for add mode
      setEditMode(false);
      setSalesPersonName('');
      setSalesPersonEmail('');
      setPhoneNumber('');
      setEmployeeID('');
      setJobPosition('Sales Store Executive');
      setOtherJobPosition('');
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditMode(false);
    setEditSalesPersonId(null);
  };

  const handlePasswordSubmit = () => {
    if (passwordInput === 'admin123') {
      // Show the unique code in the dialog
      const salesPerson = companies
        .flatMap(company => company.SalesPeople)
        .find(sp => sp._id === passwordAttemptSalesPersonId);
      setUniqueCodeToShow(salesPerson ? salesPerson.UniqueCode : 'Code Not Found');
    } else {
      alert('Incorrect password. Please try again.');
    }
    setPasswordInput('');
  };

  const handleOpenPasswordDialog = (salesPersonId) => {
    setPasswordAttemptSalesPersonId(salesPersonId);
    setPasswordDialogOpen(true);
  };

  const handleClosePasswordDialog = () => {
    setPasswordDialogOpen(false);
    setUniqueCodeToShow('');
  };

  return (
    <div style={{ marginTop: '16px' }}>
      {companies.length === 0 ? (
        <Typography align="center">No company information available.</Typography>
      ) : (
        companies.map((company) => (
          <Accordion
            key={company._id}
            expanded={expanded === company._id}
            onChange={handleAccordionChange(company._id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${company._id}-content`}
              id={`panel-${company._id}-header`}
            >
              <Typography variant="h6">{company.CompanyName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper style={{ padding: '16px' }}>
                <Typography variant="subtitle1" gutterBottom>Sales People:</Typography>
                {company.SalesPeople && company.SalesPeople.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="salespeople table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold' }}>Sales Person Name</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>Sales Person Email</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>Employee ID</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>Phone Number</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>Job Position</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>Wearable ID</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>Unique ID</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {company.SalesPeople.map((salesPerson) => (
                          <TableRow key={salesPerson._id}>
                            <TableCell>{salesPerson.SalesPersonName}</TableCell>
                            <TableCell>{salesPerson.SalesPersonEmail}</TableCell>
                            <TableCell>{salesPerson.EmployeeID || 'No ID provided'}</TableCell>
                            <TableCell>{salesPerson.PhoneNumber}</TableCell>
                            <TableCell>{salesPerson.JobPosition}</TableCell>
                            <TableCell>{salesPerson.WearableID || 'Not Assigned'}</TableCell>
                            <TableCell>
                              {'**********'}
                              <IconButton onClick={() => handleOpenPasswordDialog(salesPerson._id)}>
                                <VisibilityIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Edit">
                                <IconButton
                                  color="primary"
                                  onClick={() => handleOpenDialog(company._id, salesPerson)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  color="secondary"
                                  onClick={() => handleRemoveSalesPerson(company._id, salesPerson.SalesPersonEmail)}
                                >
                                  <RemoveCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography>No salespeople assigned yet.</Typography>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => handleOpenDialog(company._id)}
                  sx={{ marginTop: 2 }}
                >
                  Add New Salesperson
                </Button>
              </Paper>
            </AccordionDetails>
          </Accordion>
        ))
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{editMode ? 'Edit Salesperson' : 'Add New Salesperson'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Salesperson Name"
            value={salesPersonName}
            onChange={(e) => setSalesPersonName(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Salesperson Email"
            value={salesPersonEmail}
            onChange={(e) => setSalesPersonEmail(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Employee ID"
            value={employeeID}
            onChange={(e) => setEmployeeID(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="job-position-label">Job Position</InputLabel>
            <Select
              labelId="job-position-label"
              value={jobPosition}
              onChange={(e) => setJobPosition(e.target.value)}
              label="Job Position"
            >
              <MenuItem value="Sales Store Executive">Sales Store Executive</MenuItem>
              <MenuItem value="Sales Store Manager">Sales Store Manager</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          {jobPosition === 'Other' && (
            <TextField
              label="Specify Job Position"
              value={otherJobPosition}
              onChange={(e) => setOtherJobPosition(e.target.value)}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAssignSalesPerson} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={passwordDialogOpen} onClose={handleClosePasswordDialog}>
        <DialogTitle>Enter Password to View Unique Code</DialogTitle>
        <DialogContent>
          <TextField
            label="Password"
            type="password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
            fullWidth
            autoComplete="off"
            sx={{ marginBottom: 3 }}
          />
          {uniqueCodeToShow && (
            <Typography variant="subtitle1">Unique Code: {uniqueCodeToShow}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePasswordDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePasswordSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AssignSalesPeople;
