// src/components/Wearables.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const Wearables = () => {
  const [wearables, setWearables] = useState([]);
  const [wearableId, setWearableId] = useState('');

  useEffect(() => {
    fetchWearables();
  }, []);

  // Fetch the list of wearables from the backend
  const fetchWearables = () => {
    axios
      .get('https://apiserver.talaura.net/api/wearables')
      .then((response) => {
        const wearableData = response.data;

        // For each wearable, fetch additional information
        const wearablePromises = wearableData.map((wearable) => {
          return axios
            .post('https://apiserver.talaura.net/api/salesPerson/info', { wearableId: wearable.WearableID })
            .then((res) => {
              return { ...wearable, ...res.data.data };
            })
            .catch(() => {
              return { ...wearable, SalesPersonName: '', SalesPersonEmail: '', CompanyName: '' };
            });
        });

        Promise.all(wearablePromises)
          .then((updatedWearables) => {
            // Fetch docker assignment details for each wearable
            const dockerPromises = updatedWearables.map((wearable) => {
              return axios
                .get(`https://apiserver.talaura.net/api/dockers/wearable/${wearable.WearableID}`)
                .then((res) => {
                  return { ...wearable, DockerAssignment: res.data.dockerId || '' };
                })
                .catch(() => {
                  return { ...wearable, DockerAssignment: '' };
                });
            });

            Promise.all(dockerPromises).then((finalWearables) => {
              setWearables(finalWearables);
            });
          })
          .catch((error) => {
            console.error('Error fetching wearables:', error);
          });
      })
      .catch((error) => {
        console.error('Error fetching wearables:', error);
      });
  };

  // Handle adding a new wearable
  const handleAddWearable = () => {
    if (!wearableId.trim()) {
      alert('Please enter a valid Wearable ID.');
      return;
    }

    axios
      .post('https://apiserver.talaura.net/api/wearables', { WearableID: wearableId })
      .then(() => {
        setWearableId('');
        fetchWearables(); // Refresh the list of wearables
      })
      .catch((error) => {
        console.error('Error adding wearable:', error);
      });
  };

  // Handle removing a wearable
  const handleRemoveWearable = (id) => {
    if (window.confirm('Are you sure you want to delete this wearable?')) {
      axios
        .delete(`https://apiserver.talaura.net/api/wearables/${id}`)
        .then(() => {
          fetchWearables(); // Refresh the list of wearables
        })
        .catch((error) => {
          console.error('Error removing wearable:', error);
        });
    }
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="wearables table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Wearable ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Sales Person Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Sales Person Email</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Company Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Docker Assignment</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wearables.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No wearables available.
                </TableCell>
              </TableRow>
            ) : (
              wearables.map((wearable) => (
                <TableRow key={wearable._id}>
                  <TableCell>{wearable.WearableID}</TableCell>
                  <TableCell>{wearable.SalesPersonName || ''}</TableCell>
                  <TableCell>{wearable.SalesPersonEmail || ''}</TableCell>
                  <TableCell>{wearable.CompanyName || ''}</TableCell>
                  <TableCell>{wearable.DockerAssignment || ''}</TableCell> {/* Displaying Docker Assignment */}
                  <TableCell>
                    <IconButton color="secondary" onClick={() => handleRemoveWearable(wearable._id)}>
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ marginTop: '24px' }}>
        <TextField
          label="Wearable ID"
          value={wearableId}
          onChange={(e) => setWearableId(e.target.value)}
          fullWidth
          autoComplete="off"
          sx={{ marginBottom: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleAddWearable}>
          Add Wearable
        </Button>
      </div>
    </div>
  );
};

export default Wearables;