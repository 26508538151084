// src/components/Unassigned.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Select, MenuItem, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const socket = io('https://apiserver.talaura.net/');

const Unassigned = () => {
  const [unassignedDockers, setUnassignedDockers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [stores, setStores] = useState([]);
  const [editRow, setEditRow] = useState(localStorage.getItem('editRow') || null);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedStore, setSelectedStore] = useState('');

  useEffect(() => {
    fetchUnassignedDockers();
    fetchCompanies();

    // Listen for updates via WebSocket
    socket.on('updateDockerData', () => {
      fetchUnassignedDockers();
    });

    return () => {
      socket.off('updateDockerData');
    };
  }, []);

  const fetchUnassignedDockers = () => {
    axios.get('https://apiserver.talaura.net/api/dockers')
      .then(response => {
        const unassigned = response.data.filter(docker => docker.Assigned === false);
        setUnassignedDockers(unassigned);
      })
      .catch(error => {
        console.error('Error fetching unassigned dockers:', error);
      });
  };

  const fetchCompanies = () => {
    axios.get('https://apiserver.talaura.net/api/companies')
      .then(response => {
        // Sort companies by CompanyName alphabetically
        const sortedCompanies = response.data.sort((a, b) =>
          a.CompanyName.localeCompare(b.CompanyName)
        );
        setCompanies(sortedCompanies);
      })
      .catch(error => {
        console.error('Error fetching companies:', error);
      });
  };

  const handleAssignClick = (dockerId) => {
    setEditRow(dockerId);
    localStorage.setItem('editRow', dockerId); // Persist editRow state in local storage
    setSelectedCompany('');
    setSelectedStore('');
    setStores([]);
  };

  const handleCompanyChange = (companyId) => {
    setSelectedCompany(companyId);
    // Fetch stores based on the selected company
    const company = companies.find(comp => comp._id === companyId);
    const sortedStores = (company?.Stores || []).sort((a, b) => a.Location.localeCompare(b.Location)); // Sort stores by Location
    setStores(sortedStores);
  };

  const handleStoreChange = (storeId) => {
    setSelectedStore(storeId);
  };

  const handleReassignDocker = (dockerId) => {
    const store = stores.find(s => s.StoreID === selectedStore);
    const companyObjectId = selectedCompany;

    if (!store || !companyObjectId) {
      alert('Please select a valid company and store.');
      return;
    }

    axios.patch(`https://apiserver.talaura.net/api/dockers/${dockerId}`, {
      Assigned: true,
      StoreID: store.StoreID,
      Location: store.Location,
      Company: companyObjectId
    })
      .then(() => {
        // Update the frontend state by refetching the data
        fetchUnassignedDockers();
        setEditRow(null);
        localStorage.removeItem('editRow'); // Remove editRow state from local storage
      })
      .catch(error => {
        console.error('Error reassigning docker:', error);
      });
  };

  return (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label="unassigned docker table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>DockerID</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {unassignedDockers.length === 0 ? (
            <TableRow>
              <TableCell colSpan={2} align="center">
                No unassigned dockers available.
              </TableCell>
            </TableRow>
          ) : (
            unassignedDockers.map((docker) => (
              <TableRow key={docker._id}>
                <TableCell>{docker.DockerID}</TableCell>
                <TableCell>
                  {editRow === docker._id ? (
                    <>
                      {/* Company Selection Dropdown */}
                      <Select
                        value={selectedCompany || ''}
                        onChange={(e) => handleCompanyChange(e.target.value)}
                        displayEmpty
                        sx={{ marginRight: 1 }}
                      >
                        <MenuItem value="" disabled>Select Company</MenuItem>
                        {companies.map((company) => (
                          <MenuItem key={company._id} value={company._id}>
                            {company.CompanyName}
                          </MenuItem>
                        ))}
                      </Select>

                      {/* Store Selection Dropdown */}
                      <Select
                        value={selectedStore || ''}
                        onChange={(e) => handleStoreChange(e.target.value)}
                        displayEmpty
                        sx={{ marginRight: 1 }}
                      >
                        <MenuItem value="" disabled>Select Store</MenuItem>
                        {stores.map((store) => (
                          <MenuItem key={store.StoreID} value={store.StoreID}>
                            {store.Location} (ID: {store.StoreID})
                          </MenuItem>
                        ))}
                      </Select>

                      {/* Confirm Assignment Button */}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleReassignDocker(docker._id)}
                      >
                        Assign
                      </Button>
                    </>
                  ) : (
                    <IconButton
                      color="primary"
                      onClick={() => handleAssignClick(docker._id)}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Unassigned;
