import React, { useEffect, useState } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const socket = io("https://apiserver.talaura.net/"); // Update to match your backend address

const CompanyStoreInfo = () => {
  const [companiesData, setCompaniesData] = useState([]);
  const [expandedAccordion, setExpandedAccordion] = useState(
    localStorage.getItem('expandedCompanyAccordion') || false
  );
  const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);
  const [openAddStoreDialog, setOpenAddStoreDialog] = useState(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState('');
  const [newCompanyDisplayName, setNewCompanyDisplayName] = useState('');
  const [newCompanyLocation, setNewCompanyLocation] = useState('');
  const [newCompanyStoreID, setNewCompanyStoreID] = useState('');
  const [storeLocation, setStoreLocation] = useState('');
  const [storeID, setStoreID] = useState('');
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedStoreID, setSelectedStoreID] = useState(null);

  useEffect(() => {
    fetchCompaniesData();

    // Listen for WebSocket updates
    socket.on("updateCompanyData", (updatedCompany) => {
      setCompaniesData(prevCompanies => {
        const index = prevCompanies.findIndex(c => c._id === updatedCompany._id);
        if (index !== -1) {
          const newCompanies = [...prevCompanies];
          newCompanies[index] = updatedCompany;
          return newCompanies;
        } else {
          return [...prevCompanies, updatedCompany];
        }
      });
    });

    return () => {
      socket.off("updateCompanyData");
    };
  }, []);

  const fetchCompaniesData = () => {
    axios.get('https://apiserver.talaura.net/api/companies')
      .then(response => {
        const sortedCompanies = response.data.sort((a, b) =>
          a.CompanyName.localeCompare(b.CompanyName)
        );
        setCompaniesData(sortedCompanies);
      })
      .catch(error => {
        console.error('Error fetching companies:', error);
      });
  };

  const handleAccordionChange = (companyId) => (event, isExpanded) => {
    const newExpandedState = isExpanded ? companyId : false;
    setExpandedAccordion(newExpandedState);
    localStorage.setItem('expandedCompanyAccordion', newExpandedState);
  };

  const generateUniqueStoreID = () => {
    let uniqueStoreID = `ST${Date.now()}${Math.floor(Math.random() * 1000)}`;
    while (companiesData.some(company =>
      company.Stores.some(store => store.StoreID === uniqueStoreID)
    )) {
      uniqueStoreID = `ST${Date.now()}${Math.floor(Math.random() * 1000)}`;
    }
    return uniqueStoreID;
  };

  const handleOpenAddCompanyDialog = () => {
    setOpenAddCompanyDialog(true);
  };

  const handleCloseAddCompanyDialog = () => {
    setOpenAddCompanyDialog(false);
    setNewCompanyName('');
    setNewCompanyDisplayName('');
    setNewCompanyLocation('');
    setNewCompanyStoreID('');
  };

  const handleAddCompany = () => {
    if (!newCompanyName.trim() || !newCompanyDisplayName.trim() || !newCompanyLocation.trim()) {
      alert('Please fill in all required fields: Company Name, Company Display Name, and Location.');
      return;
    }

    let storeIDValue = newCompanyStoreID.trim();
    if (storeIDValue) {
      const isStoreIDExists = companiesData.some(company =>
        company.Stores.some(store => store.StoreID === storeIDValue)
      );
      if (isStoreIDExists) {
        alert('The StoreID already exists. Please use a different StoreID.');
        return;
      }
    } else {
      storeIDValue = generateUniqueStoreID();
    }

    const newStore = {
      StoreID: storeIDValue,
      Location: newCompanyLocation,
    };

    const sanitizedDisplayName = newCompanyDisplayName.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    const companyEmailTalaura = `${sanitizedDisplayName}@talaura.ai`;

    const newCompany = {
      CompanyName: newCompanyName,
      CompanyDisplayName: newCompanyDisplayName,
      CompanyEmailTalaura: companyEmailTalaura,
      Stores: [newStore],
    };

    axios.post('https://apiserver.talaura.net/api/companies', newCompany)
      .then(() => {
        console.log('Successfully added new company');
        fetchCompaniesData();
        handleCloseAddCompanyDialog();
      })
      .catch((error) => {
        console.error('Error adding new company:', error);
        if (error.response && error.response.status === 400) {
          alert('A company with the same name but a different display name already exists. Please use a unique combination of Company Name and Display Name.');
        } else {
          alert('Error adding new company: ' + (error.response ? error.response.data : error.message));
        }
      });
  };

  const handleOpenAddStoreDialog = (companyId) => {
    setSelectedCompanyId(companyId);
    setOpenAddStoreDialog(true);
  };

  const handleCloseAddStoreDialog = () => {
    setOpenAddStoreDialog(false);
    setStoreLocation('');
    setStoreID('');
  };

  const handleAddStore = () => {
    if (!storeLocation.trim()) {
      alert('Please fill in the required field: Location.');
      return;
    }

    let storeIDValue = storeID.trim();
    if (storeIDValue) {
      const isStoreIDExists = companiesData.some(company =>
        company.Stores.some(store => store.StoreID === storeIDValue)
      );
      if (isStoreIDExists) {
        alert('The StoreID already exists. Please use a different StoreID.');
        return;
      }
    } else {
      storeIDValue = generateUniqueStoreID();
    }

    const newStore = {
      StoreID: storeIDValue,
      Location: storeLocation,
    };

    const companyIndex = companiesData.findIndex(company => company._id === selectedCompanyId);
    if (companyIndex !== -1) {
      const updatedCompany = { ...companiesData[companyIndex] };
      updatedCompany.Stores.push(newStore);
      axios.put(`https://apiserver.talaura.net/api/companies/${selectedCompanyId}`, updatedCompany)
        .then(() => {
          console.log('Successfully updated company with new store');
          fetchCompaniesData();
          handleCloseAddStoreDialog();
        })
        .catch((error) => {
          console.error('Error updating company:', error);
          alert('Error updating company: ' + (error.response ? error.response.data : error.message));
        });
    }
  };

  const handleOpenDeleteConfirmationDialog = (companyId, storeID) => {
    setSelectedCompanyId(companyId);
    setSelectedStoreID(storeID);
    setOpenDeleteConfirmationDialog(true);
  };

  const handleCloseDeleteConfirmationDialog = () => {
    setOpenDeleteConfirmationDialog(false);
    setSelectedCompanyId(null);
    setSelectedStoreID(null);
  };

  const handleDeleteStore = () => {
    const companyIndex = companiesData.findIndex(company => company._id === selectedCompanyId);
    if (companyIndex !== -1) {
      const updatedCompany = { ...companiesData[companyIndex] };
      updatedCompany.Stores = updatedCompany.Stores.filter(store => store.StoreID !== selectedStoreID);

      axios.put(`https://apiserver.talaura.net/api/companies/${selectedCompanyId}`, updatedCompany)
        .then(() => {
          console.log('Successfully deleted store from company');
          fetchCompaniesData();
          handleCloseDeleteConfirmationDialog();
        })
        .catch((error) => {
          console.error('Error deleting store:', error);
          alert('Error deleting store: ' + (error.response ? error.response.data : error.message));
        });
    }
  };

  return (
    <div style={{ marginTop: '16px' }}>
      {companiesData.length === 0 ? (
        <Typography align="center">No company and store info available.</Typography>
      ) : (
        companiesData.map((company) => (
          <Accordion
            key={company._id}
            expanded={expandedAccordion === company._id}
            onChange={handleAccordionChange(company._id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${company._id}-content`}
              id={`panel-${company._id}-header`}
            >
              <Typography variant="h6">
                {company.CompanyName} ({company.CompanyDisplayName})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="company store info table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>StoreID</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Location</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {company.Stores.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          No stores available for this company.
                        </TableCell>
                      </TableRow>
                    ) : (
                      company.Stores.map((store) => (
                        <TableRow key={store.StoreID}>
                          <TableCell>{store.StoreID}</TableCell>
                          <TableCell>{store.Location}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="secondary"
                              startIcon={<RemoveCircleOutlineIcon />}
                              onClick={() => handleOpenDeleteConfirmationDialog(company._id, store.StoreID)}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ marginTop: '16px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => handleOpenAddStoreDialog(company._id)}
                >
                  Add Store
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        ))
      )}

      {/* Add New Company Section as a Dialog */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlineIcon />}
        onClick={handleOpenAddCompanyDialog}
        sx={{ marginTop: 2 }}
      >
        Add Company
      </Button>

      <Dialog open={openAddCompanyDialog} onClose={handleCloseAddCompanyDialog}>
        <DialogTitle>Add New Company</DialogTitle>
        <DialogContent>
          <TextField
            label="Company Name"
            value={newCompanyName}
            onChange={(e) => setNewCompanyName(e.target.value)}
            fullWidth
            required
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Company Display Name"
            value={newCompanyDisplayName}
            onChange={(e) => setNewCompanyDisplayName(e.target.value)}
            fullWidth
            required
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Location"
            value={newCompanyLocation}
            onChange={(e) => setNewCompanyLocation(e.target.value)}
            fullWidth
            required
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="StoreID (Optional)"
            value={newCompanyStoreID}
            onChange={(e) => setNewCompanyStoreID(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddCompanyDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddCompany} color="primary">
            Add Company
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add New Store Dialog */}
      <Dialog open={openAddStoreDialog} onClose={handleCloseAddStoreDialog}>
        <DialogTitle>Add New Store</DialogTitle>
        <DialogContent>
          <TextField
            label="Location"
            value={storeLocation}
            onChange={(e) => setStoreLocation(e.target.value)}
            fullWidth
            required
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="StoreID (Optional)"
            value={storeID}
            onChange={(e) => setStoreID(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddStoreDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddStore} color="primary">
            Add Store
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteConfirmationDialog} onClose={handleCloseDeleteConfirmationDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this store?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmationDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteStore} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CompanyStoreInfo;
