import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import CompanyDashboard from './pages/CompanyDashboard';
import AdminDashboard from './pages/AdminDashboard';

// ProtectedRoute Component
const ProtectedRoute = ({ children, redirectPath = '/' }) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} />;
  }

  return children;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    // Check authentication status from localStorage
    const loggedIn = localStorage.getItem('isAuthenticated') === 'true';
    const role = localStorage.getItem('userRole');
    setIsAuthenticated(loggedIn);
    setUserRole(role);
  }, []);

  const handleLogin = (authStatus, role) => {
    setIsAuthenticated(authStatus);
    setUserRole(role);
    localStorage.setItem('isAuthenticated', authStatus.toString());
    localStorage.setItem('userRole', role);
  };

  return (
    <Router>
      <Routes>
        {/* Login for both company and admin */}
        <Route path="/" element={<Login onLogin={handleLogin} />} />

        {/* Admin Dashboard */}
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />

        {/* Company Dashboard - Dynamic Routing */}
        <Route
          path="/company-dashboard/:companyId"
          element={
            <ProtectedRoute>
              <CompanyDashboard />
            </ProtectedRoute>
          }
        />


        {/* Redirect any unknown route to Login */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
